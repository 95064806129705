import React from 'react'

export default function RadioButton({
  id,
  text,
  name,
  onChange,
  checked,
  value,
  custom,
}) {
  return (
    <label htmlFor={id} className='radiobutton-label'>
      <input
        className='radiobutton-input'
        type='radio'
        name={name}
        id={id}
        value={value}
        onChange={onChange}
        checked={checked}
      />
      <div className='radiobutton-label-con'>
        <span className='custom-radiobutton' />
        {text}
        <div className='radiobutton-label-custom'>{custom}</div>
      </div>
    </label>
  )
}
