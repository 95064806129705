import React from 'react'
import hendPlantTwo from '../assets/img/hend-plant2.jpg'
function ContactPage() {
  return (
    <section className='contact'>
      <div className='contact-wrapper'>
        <div className='contact-inner'>
          <div className='contact-head'>
            <h2>To Contact Us</h2>
            <div className='contact-head-text'>We will call you back</div>
            <div className='contact-head-input'>
              <input
                type='text'
                className='input'
                placeholder='+1 XXX XXX XX XX'
              />
              <button className='btn'>book a call </button>
            </div>
          </div>
          <div className='contact-mid'>
            <h4> Our Contact</h4>
          </div>
          <div className='contact-footer'>
            <div className='contact-footer-tel'>
              <a href='tel:14372404249'>+1 (437) 240-4249</a>
            </div>
            <div className='contact-footer-address'>
              61 FREDRICK TISDALE DRIVE TORONTO, ON M3K 2B8
            </div>
          </div>
        </div>
        <div className='contact-img'>
          <img src={hendPlantTwo} alt='hend Plant' />
        </div>
      </div>
    </section>
  )
}

export { ContactPage }
