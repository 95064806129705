import React from 'react'
import { NavLink } from 'react-router-dom'
import authBg from '../assets/img/auth-bg.jpg'
import { ReactComponent as EditIcon } from '../assets/svg/editIcon.svg'
import { ReactComponent as CheckIcon } from '../assets/svg/checkIcon.svg'
function SignUp() {
  return (
    <div
      className='auth'
      style={{ background: `url(${authBg}) center / cover no-repeat` }}
    >
      <div className='auth-container'>
        <div className='auth__inner'>
          <div className='auth-box sing'>
            <h2>Sign up</h2>
            <div className='auth-box-description'>
              Become a member and enjoy personalized gift recommendations, fast
              checkout, and more.
            </div>

            <div className='auth-box-tel'>
              <div className='auth-box-tel-input'>
                <CheckIcon /> +380980275095
              </div>
              <EditIcon />
            </div>

            <div className='auth-box-input'>
              <label>Enter code from sms</label>
              <input type='text' className='input' placeholder='XX XX XX' />
              <div className='auth-box-input-area'>
                Please check your phone for a message containing a code to enter
              </div>
            </div>
            <button className='btn'>continue</button>
            <div className='auth-box-resend'>
              Didn't receive a code? <NavLink to='/'>Resend code</NavLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export { SignUp }
