export default [
  {
    id: '1',
    img: 'african-daisy.jpg',
    name: 'african daisy',
    subname: 'osteospermum mix',
    description:
      'Large, colourful blossoms with distinctive dark centers bloom profusely summer to frost. Plants are easy-to-grow and bushy, producing their lovely flowers on long, strong stems. Ideal for beds, borders and containers. Start Indoors 8-10 weeks before last expected frost',
    daysSprout: '7-14',
    seedSpacing: '1"/2.5cm',
    plantSpacing: '12"/30cm',
    daysHarvest: 'N/A',
    plantHeight: '36"/90cm',
    LightRequired: 'Full Sun',
    price: 1.99,
  },
  {
    id: '2',
    img: 'ageratum.jpg',
    name: 'ageratum',
    subname: 'dwarf blue mink',
    description:
      'Like a sky full of fluffy blue clouds, these flowers are borne in tight clusters atop low-growing plants. This annual blooms continuously from early summer until fall frost. Ideal for beds, borders and containers, plants blend nicely with yellow marigolds, petunias or pansies. Start Indoors 6-8 weeks before last expected frost.',
    daysSprout: '7-14',
    seedSpacing: '0.5"/12mm',
    plantSpacing: '10"/25cm',
    daysHarvest: 'N/A',
    plantHeight: '10"/25cm',
    LightRequired: 'Full/Partial',
    price: 1.99,
  },
  {
    id: '3',
    img: 'strawflower.jpg',
    name: 'strawflower',
    subname: 'helichrysum',
    description:
      'One of the most popular everlasting flowers, strawflowers produce colourful, pompom-like flowers that are excellent for use fresh and dried. They come in a wide range of colours, are easy-to-grow, and need only moderate watering. Start Indoors 4-6 weeks before last expected frost',
    daysSprout: '7-14',
    seedSpacing: '1"/2.5cm',
    plantSpacing: '12"/30cm',
    daysHarvest: 'N/A',
    plantHeight: '36"/90cm',
    LightRequired: 'Full Sun',
    price: 1.99,
  },
  {
    id: '4',
    img: 'sunflower.jpg',
    name: 'sunflower',
    subname: 'autumn beauty',
    description:
      'Autumn Beauty produces armloads of colourful flowers. Strong stemmed flowers average 6" (15cm) across and come in a unique array of colours, including creamy yellow, gold, bronze, mahogany and purplish, each with a dark center. Direct sow in early spring as soon as soil is workable',
    daysSprout: '14-21',
    seedSpacing: '6"/15cm',
    plantSpacing: '24"/60cm',
    daysHarvest: 'N/A',
    plantHeight: '6"/1.8m',
    LightRequired: 'Full Sun',
    price: 1.99,
  },
  {
    id: '5',
    img: 'schizanthus.jpg',
    name: 'schizanthus',
    subname: 'angel wings mix',
    description:
      'Lovely, bushy plants with attractive fern-like foliage produce masses of delicate and colourful flowers that resemble small butterflies or orchids. This easy-to-grow annual comes in many colours and bicolour combinations. Start Indoors 8-10 weeks before last expected frost',
    daysSprout: '21-28',
    seedSpacing: '1"/2.5cm',
    plantSpacing: '12"/30cm',
    daysHarvest: 'N/A',
    plantHeight: '18"/45cm',
    LightRequired: 'Full/Partial',
    price: 1.99,
  },
  {
    id: '6',
    img: 'baby-blue-eyes.jpg',
    name: 'baby blue eyes',
    subname: 'nemophila menziesii',
    description:
      'This hardy annual produces masses of delicate, cup-shaped, sky-blue flowers with cheerful white centers. The attractive plants are neat, bright green mounds of tufted foliage. Excellent for beds, borders, walkways, and meadows. Start Indoors 8-10 weeks before last expected frost',
    daysSprout: '7-14',
    seedSpacing: '0.5"/12mm',
    plantSpacing: '8"/20cm',
    daysHarvest: 'N/A',
    plantHeight: '9"/23cm',
    LightRequired: 'Full/Partial',
    price: 1.99,
  },
  {
    id: '7',
    img: 'bachelors-button.jpg',
    name: "bachelor's button",
    subname: 'blue boy double',
    description:
      'One of the most widely grown annuals. Plants produce abundant bright, beautifully coloured, long-stemmed, double flowers that will bloom from early summer through fall. Often cut for bouquets and boutonnieres. Direct sow outdoors after last expected frost',
    daysSprout: '7-14',
    seedSpacing: '1"/2.5cm',
    plantSpacing: '12"/30cm',
    daysHarvest: 'N/A',
    plantHeight: '24"/60cm',
    LightRequired: 'Full Sun',
    price: 1.99,
  },
  {
    id: '8',
    img: 'celosia.jpg',
    name: 'celosia',
    subname: 'fine feathered mix',
    description:
      'Heat-loving, sun-loving plants are smothered with 12" (30cm) tall flower plumes from midsummer into fall. The showy feathery flowers and attractive leaves make this unique variety a real attention getter. Stunning combined with gray-leafed plants. Use in mass plantings, mixed borders; cutting and drying. Start Indoors 6-8 weeks before last expected frost',
    daysSprout: '7-14',
    seedSpacing: '0.5"/12mm',
    plantSpacing: '18"/45cm',
    daysHarvest: 'N/A',
    plantHeight: '30"/75cm',
    LightRequired: 'Full Sun',
    price: 2.99,
  },
  {
    id: '9',
    img: 'aster.jpg',
    name: 'aster',
    subname: 'powder puff mix',
    description:
      'These large, chrysanthemum-type double flowers come in mixed colours with creamy yellow centers. Plants are easy-to-grow and bloom throughout summer. Excellent for tall borders or beds, and a popular cut flower. Start Indoors 6-8 weeks before last expected frost',
    daysSprout: '7-14',
    seedSpacing: '1"/2.5cm',
    plantSpacing: '15"/38cm',
    daysHarvest: 'N/A',
    plantHeight: '24"/60cm',
    LightRequired: 'Full Sun',
    price: 1.99,
  },
  {
    id: '10',
    img: 'ornamental-corn.jpg',
    name: 'ornamental corn',
    subname: 'japanese stars',
    description:
      'An extremely beautiful ornamental corn! With majestic, graceful foliage, this variegated plant features striped leaves with green, white, yellow and pink markings creating a stunning view. The perfect selection to use as a background plant or along taller borders to create a unique landscaping design. Color develops better when plants are widely spaced.',
    daysSprout: '7-14',
    seedSpacing: '1"/2.5cm',
    plantSpacing: '15"/38cm',
    daysHarvest: 'N/A',
    plantHeight: '24"/60cm',
    LightRequired: 'Full Sun',
    price: 2.99,
  },
  {
    id: '11',
    img: 'bachelors-button.jpg',
    name: "bachelor's button",
    subname: 'red ball',
    description:
      "One of the world's most widely grown annuals. Easy-to-grow plants produce abundant bright, beautifully coloured, long-stemmed, double flowers that will bloom from early summer through fall. Excellent when massed in beds and borders, and when cut for bouquets and boutonnieres. Direct sow outdoors after last expected frost",
    daysSprout: '7-14',
    seedSpacing: '1"/2.5cm',
    plantSpacing: '12"/30cm',
    daysHarvest: 'N/A',
    plantHeight: '24"/60cm',
    LightRequired: 'Full Sun',
    price: 1.99,
  },
  {
    id: '12',
    img: 'bachelors-button-red.jpg',
    name: "bachelor's button",
    subname: 'red ball',
    description:
      "One of the world's most widely grown annuals. Easy-to-grow plants produce abundant bright, beautifully coloured, long-stemmed, double flowers that will bloom from early summer through fall. Excellent when massed in beds and borders, and when cut for bouquets and boutonnieres. Direct sow outdoors after last expected frost",
    daysSprout: '7-14',
    seedSpacing: '1"/2.5cm',
    plantSpacing: '12"/30cm',
    daysHarvest: 'N/A',
    plantHeight: '24"/60cm',
    LightRequired: 'Full Sun',
    price: 1.99,
  },
  {
    id: '13',
    img: 'coleus.jpg',
    name: 'coleus',
    subname: 'rainbow mix',
    description:
      'Few annuals will grow as easily in shady areas. Plants are grown for their exotic foliage, which comes in a variety of bold colours, including multi-hued leaf patterns. They also make brilliant houseplants. Start Indoors 6-8 weeks before last expected frost',
    daysSprout: '7-14',
    seedSpacing: '1"/2.5cm',
    plantSpacing: '15"/38cm',
    daysHarvest: 'N/A',
    plantHeight: '18"/45cm',
    LightRequired: 'Partial Sun',
    price: 2.99,
  },
  {
    id: '14',
    img: 'cactus.jpg',
    name: 'cactus',
    subname: 'desert mix',
    description:
      'This is a carefully selected blend for a variety of heights and shapes. After the first year, many produce spectacularly coloured, satiny blooms. Plants are long-lived perennials that are perfect for bright areas indoors. Year-round indoors',
    daysSprout: '14-49',
    seedSpacing: '0"/0mm',
    plantSpacing: '2"/5cm',
    daysHarvest: 'N/A',
    plantHeight: '12"/30cm',
    LightRequired: 'Full Sun',
    price: 3.99,
  },
  {
    id: '15',
    img: 'chinese-lantern.jpg',
    name: 'chinese lantern',
    subname: 'physalis',
    description:
      'Inflated orange-red papery seed pods resembling traditional Chinese lanterns develop late in the summer and are excellent for use in fresh and dried arrangements. Often associated with good luck and prosperity. Start Indoors 8-10 weeks before last expected frost',
    daysSprout: '14-49',
    seedSpacing: '1"/2.5cm',
    plantSpacing: '18"/45cm',
    daysHarvest: 'N/A',
    plantHeight: ' 18"/45cm',
    LightRequired: 'Full/Partial',
    price: 3.99,
  },
  {
    id: '16',
    img: 'scabiosa.jpg',
    name: 'scabiosa',
    subname: "shepherd's",
    description:
      'These pure blue flowers will brighten up any perennial bed, growing in masses atop attractive green foliage on erect stems. Plant with Yarrow, Black-Eyed Susan and Alaska Daisies for a stunning effect. Shepherds Scabiosa is ideal for borders and rock gardens.',
    daysSprout: '20-25',
    seedSpacing: '1"/2.5cm',
    plantSpacing: '12"/30cm',
    daysHarvest: 'N/A',
    plantHeight: '24"/60cm',
    LightRequired: 'Full Sun',
    price: 2.99,
  },
  {
    id: '18',
    img: 'foxglove.jpg',
    name: 'foxglove',
    subname: 'foxy digitalis',
    description:
      'Tall stately spikes bear many beautiful speckle-throated flowers in an array of colours atop leafy stems. Add an outstanding vertical effect at the rear of a border or in natural settings, as they will self-seed. Direct sow outdoors after last expected frost',
    daysSprout: '7-14',
    seedSpacing: '1"/2.5cm',
    plantSpacing: '24"/60cm',
    daysHarvest: 'N/A',
    plantHeight: '6"/1.8m',
    LightRequired: 'Full/Partial',
    price: 2.99,
  },
  {
    id: '19',
    img: 'bells-of-ireland.jpg',
    name: 'bells of ireland',
    subname: 'shell flower',
    description:
      'This unique annual produces long, graceful stems covered with lime green, bell-like blossoms. The true flowers, which form inside the "bells", are small and fragrant. Use in beds, for fresh cut flowers or dried. Start Indoors 8-10 weeks before last expected frost',
    daysSprout: '28-35',
    seedSpacing: '0.5"/12mm',
    plantSpacing: '15"/38cm',
    daysHarvest: 'N/A',
    plantHeight: '36"/90cm',
    LightRequired: 'Full/Partial',
    price: 1.99,
  },
  {
    id: '20',
    img: 'carnation.jpg',
    name: 'carnation',
    subname: 'chabaud mix',
    description:
      'This bouquet staple is known for its colourful, fragrant, and fringed flowers. Fully double blooms are borne on strong stems and come in white, red, and shades of pink. Mixes well with other sun-loving flowers in your garden. Start Indoors 8-10 weeks before last expected frost',
    daysSprout: '21-28',
    seedSpacing: '1"/2.5cm',
    plantSpacing: '12"/30cm',
    daysHarvest: 'N/A',
    plantHeight: '24"/60cm',
    LightRequired: 'Full Sun',
    price: 1.99,
  },
  {
    id: '21',
    img: 'kochia.jpg',
    name: 'kochia',
    subname: 'burning bush',
    description:
      'These attractive, upright, globe-shaped bushy plants are grown for their delicate light green leaves that become a rich red in fall. Plants are easy-to-grow, making excellent hedges, screens and accents. Start Indoors 4-6 weeks before last expected frost',
    daysSprout: '7-14',
    seedSpacing: '2"/5cm',
    plantSpacing: '36"/90cm',
    daysHarvest: 'N/A',
    plantHeight: '36"/90cm',
    LightRequired: 'Full Sun',
    price: 1.99,
  },
  {
    id: '22',
    img: 'lupine.jpg',
    name: 'lupine',
    subname: 'mixed colours',
    description:
      'Lupine produces spectacular flower spikes in a wide range of separate colours and bicolours. Plants are mounded and covered with deeply cut attractive foliage. Use in mass plantings or in the mid to back portion of mixed beds. Excellent cut flower. Start Indoors 6-8 weeks before last expected frost',
    daysSprout: '21-28',
    seedSpacing: '1"/2.5cm',
    plantSpacing: '18"/45cm',
    daysHarvest: 'N/A',
    plantHeight: '4"/1.2m',
    LightRequired: 'Full/Partial',
    price: 1.99,
  },
  {
    id: '23',
    img: 'cosmos.jpg',
    name: 'cosmos',
    subname: 'sensation mix',
    description:
      'Few plants grow as rapidly as cosmos and, fewer still, offer such graceful flowers. This All American Award winner produced 5-inch (12.5cm), single daisy-like flowers in white, pink and red. Foliage is delicate, feathery green. Useful as a background, border or pots, and as cut flowers. Start Indoors 2-4 weeks before last expected frost',
    daysSprout: '7-14',
    seedSpacing: '1"/2.5cm',
    plantSpacing: '12"/30cm',
    daysHarvest: 'N/A',
    plantHeight: '5"/1.5m',
    LightRequired: 'Full Sun',
    price: 1.99,
  },
  {
    id: '24',
    img: 'dahlias.jpg',
    name: 'dahlias',
    subname: "unwin's bedding mix",
    description:
      'These dwarf plants bloom prodigiously, producing semi double flowers in all colours except blue. These easy-to-grow natives of Mexico will bloom from summer until early fall, and can be used in massed plantings, border or containers. Flowers make striking, cut-flower arrangements. Start Indoors 6-8 weeks before last expected frost',
    daysSprout: '7-14',
    seedSpacing: '1"/2.5cm',
    plantSpacing: '15"/38cm',
    daysHarvest: 'N/A',
    plantHeight: '18"/45cm',
    LightRequired: 'Full Sun',
    price: 2.99,
  },
  {
    id: '25',
    img: 'daisy.jpg',
    name: 'daisy',
    subname: 'meadow single white',
    description:
      'For the flower addicts who want flowers in the garden and the lawn. For growing in the lawn, simply scatter the seed and keep it moist, then all you need to worry about is mowing them down after flowering each year. Meadow Daisies look best when sown in masses, and used to create a sea of beautiful white flowers. Direct sow outdoors after last expected frost',
    daysSprout: '7-14',
    seedSpacing: '6"/15cm',
    plantSpacing: '18"/45cm',
    daysHarvest: 'N/A',
    plantHeight: '6"/15cm',
    LightRequired: 'Full/Partial',
    price: 1.99,
  },
  {
    id: '26',
    img: 'marigold.jpg',
    name: 'marigold',
    subname: 'sparky mix',
    description:
      'Marigolds are among the most popular and easiest to grow annuals, blooming all summer until fall frost. This dwarf, French type, produces small yellow and orange flowers that resemble miniature carnations. Direct sow outdoors after last expected frost',
    daysSprout: '1-7',
    seedSpacing: '1"/2.5cm',
    plantSpacing: '8"/20cm',
    daysHarvest: 'N/A',
    plantHeight: '12"/30cm',
    LightRequired: 'Full Sun',
    price: 1.99,
  },
  {
    id: '27',
    img: 'pansy.jpg',
    name: 'pansy',
    subname: 'aalsmeer giant blue',
    description:
      'Large, beautiful blue flowers make this pansy variety a favourite flower. Plants are easy-to-grow and have a long blooming season. Great used for edging or low borders, in rock gardens and in containers. Start Indoors 8-10 weeks before last expected frost',
    daysSprout: '14-21',
    seedSpacing: '0.5"/12mm',
    plantSpacing: '10"/25cm',
    daysHarvest: 'N/A',
    plantHeight: '8"/20cm',
    LightRequired: 'Full/Partial',
    price: 2.99,
  },
  {
    id: '28',
    img: 'calendula.jpg',
    name: 'calendula',
    subname: 'orange king',
    description:
      'One of the most popular and easiest to grow annuals. Large, colourful, double blossoms are borne on strong, tall stems above clean and attractive foliage. They are known to repel pests and make outstanding cut flowers. Start Indoors 6-8 weeks before last expected frost',
    daysSprout: '7-14',
    seedSpacing: '0.5"/12mm',
    plantSpacing: '15"/38cm',
    daysHarvest: 'N/A',
    plantHeight: '24"/60cm',
    LightRequired: 'Full Sun',
    price: 2.99,
  },
  {
    id: '29',
    img: 'marigold-crackerjack.jpg',
    name: 'marigold',
    subname: 'crackerjack tall',
    description:
      'Among the most popular and easiest-to-grow annuals, blooming all summer until fall frost. This tall African type produces large yellow, orange and gold carnation-like flowers. Ideal against walls, fences and as a cut flower. Direct sow outdoors after last expected frost',
    daysSprout: '7-14',
    seedSpacing: '1"/2.5cm',
    plantSpacing: '18"/45cm',
    daysHarvest: 'N/A',
    plantHeight: '36"/90cm',
    LightRequired: 'Full Sun',
    price: 2.99,
  },
  {
    id: '30',
    img: 'pansy-swiss.jpg',
    name: 'pansy',
    subname: 'swiss giants',
    description:
      'Large multi-coloured flowers marked with "faces". Plants are easy-to-grow and have a long blooming season. Use for edging, low borders, mixed with spring flowering bulbs, in rock gardens, and in containers. Start Indoors 8-10 weeks before last expected frost',
    daysSprout: '14-21',
    seedSpacing: '0.5"/12mm',
    plantSpacing: '10"/25cm',
    daysHarvest: 'N/A',
    plantHeight: '8"/20cm',
    LightRequired: 'Full/Partial',
    price: 2.99,
  },
  {
    id: '31',
    img: 'passionflower.jpg',
    name: 'passionflower',
    subname: 'blue crown',
    description:
      'This native to the American tropics makes an attractive vine for trellises or can be left alone to wander over your shrubs and plants. The fascinating, complex flowers display colours of white, pink and blueish purple. Start Indoors 4-6 weeks before last expected frost',
    daysSprout: '7-14',
    seedSpacing: '6"/15cm',
    plantSpacing: '10"/3m',
    daysHarvest: 'N/A',
    plantHeight: '30"/8.5m',
    LightRequired: 'Full Sun',
    price: 2.99,
  },
  {
    id: '32',
    img: 'nasturtium.jpg',
    name: 'nasturtium',
    subname: 'tall single mix ',
    description:
      'These plants are semi-hanging and covered in colourful semi-double blooms. Use in hanging baskets, planters, or for spilling on a low wall. The young leaves and flowers have a watercress flavour and can be used in salads. Direct sow outdoors after last expected frost',
    daysSprout: '7-14',
    seedSpacing: '2"/5cm',
    plantSpacing: '8"/20cm',
    daysHarvest: 'N/A',
    plantHeight: '24"/60cm',
    LightRequired: 'Full/Partial',
    price: 1.99,
  },
  {
    id: '33',
    img: 'babys-breath.jpg',
    name: "baby's breath",
    subname: 'gypsophila',
    description:
      'Mounded plants are topped with masses of tiny white flowers borne on wiry, multi-branched stems. The abundance of flowers combines with light foliage to create a cloud-like effect in the garden. It is a must-grow for bouquets and is excellent in dried floral arrangements. Direct sow in early spring as soon as soil is workable',
    daysSprout: '7-14',
    seedSpacing: '1"/2.5cm',
    plantSpacing: '24"/60cm',
    daysHarvest: 'N/A',
    plantHeight: '4"/1.2m',
    LightRequired: 'Full Sun',
    price: 1.99,
  },
  {
    id: '34',
    img: 'poppy.jpg',
    name: 'poppy',
    subname: 'iceland mixed colours',
    description:
      'Eye-catching, cup-shaped flowers, textured like crepe paper, are produced atop tall stems. Blooms open from nodding, puffy buds. Use in mass plantings, mixed with other flowers, or as a border. Start Indoors 6-8 weeks before last expected frost',
    daysSprout: '7-14',
    seedSpacing: '0.5"/12mm',
    plantSpacing: '10"/25cm',
    daysHarvest: 'N/A',
    plantHeight: '18"/45cm',
    LightRequired: 'Full/Partial',
    price: 2.99,
  },
  {
    id: '35',
    img: 'sweet-william.jpg',
    name: 'sweet william',
    subname: 'dianthus double mix',
    description:
      'Attractive, mounded plants bear large, flat flower heads in a variety of colours including white, pink, rose, red, deep violet, and bicolours. The fragrant double flowers are borne from late spring to mid-summer, and are excellent for cutting. Use in borders, naturalized, and in rock gardens. Start Indoors 8-10 weeks before last expected frost',
    daysSprout: '7-14',
    seedSpacing: '6"/15cm',
    plantSpacing: '10"/3m',
    daysHarvest: 'N/A',
    plantHeight: '30"/8.5m',
    LightRequired: 'Full Sun',
    price: 1.99,
  },
  {
    id: '36',
    img: 'gerbera-daisy.jpg',
    name: 'gerbera daisy',
    subname: 'single mix',
    description:
      'The large, exquisite coloured daisy-like flowers are prized for their continuous display from spring to late fall. Blossoms make superior, long-lasting cut flowers. Where ground freezes, grow in pots. Direct sow outdoors after last expected frost',
    daysSprout: '14-28',
    seedSpacing: '1"/2.5cm',
    plantSpacing: '15"/38cm',
    daysHarvest: 'N/A',
    plantHeight: '18"/45cm',
    LightRequired: 'Full Sun',
    price: 3.99,
  },
  {
    id: '37',
    img: 'chrysanthemum.jpg',
    name: 'chrysanthemum',
    subname: 'merry mix',
    description:
      'Plants are easy-to-grow from seed and produce masses of spectacular flowers in a wide range of colours. Plants grow knee-high and mix well with other sun-loving flowers. They make excellent cut flowers. Start indoors 10-12 weeks before last expected frost',
    daysSprout: '14-21',
    seedSpacing: '1"/2.5cm',
    plantSpacing: '30"/75cm',
    daysHarvest: 'N/A',
    plantHeight: '24"/60cm',
    LightRequired: 'Full Sun',
    price: 1.99,
  },
  {
    id: '38',
    img: 'balloon-flower.jpg',
    name: 'balloon flower',
    subname: 'sentimental blue',
    description:
      'Balloon flower has gained its name by the unusual "balloon" shape of its bud before it opens into striking, five-lobed cups of blue to purple. Long-lasting blooms in July and August make excellent cut flowers, if the cut ends of the stems are seared. Start Indoors 6-8 weeks before last expected frost',
    daysSprout: '14-21',
    seedSpacing: '3"/7.5cm',
    plantSpacing: '10"/25cm',
    daysHarvest: 'N/A',
    plantHeight: '36"/90cm',
    LightRequired: 'Partial Sun',
    price: 1.99,
  },
  {
    id: '39',
    img: 'candytuft.jpg',
    name: 'candytuft',
    subname: 'dwarf fairy mix',
    description:
      'This low-growing annual produces clusters of tiny rounded flowers all summer long. The fragrant flowers come in white, pink, red and purple. Plants make excellent borders and edgings, particularly in rock gardens or other areas of the garden where a trailing plant is needed. Start Indoors 8-10 weeks before last expected frost',
    daysSprout: '14-21',
    seedSpacing: '1"/2.5cm',
    plantSpacing: '12"/30cm',
    daysHarvest: 'N/A',
    plantHeight: '12"/30cm',
    LightRequired: 'Partial Sun',
    price: 1.99,
  },
  {
    id: '40',
    img: 'candytuft.jpg',
    name: 'candytuft',
    subname: 'dwarf fairy mix',
    description:
      'This low-growing annual produces clusters of tiny rounded flowers all summer long. The fragrant flowers come in white, pink, red and purple. Plants make excellent borders and edgings, particularly in rock gardens or other areas of the garden where a trailing plant is needed. Start Indoors 8-10 weeks before last expected frost',
    daysSprout: '14-21',
    seedSpacing: '1"/2.5cm',
    plantSpacing: '12"/30cm',
    daysHarvest: 'N/A',
    plantHeight: '12"/30cm',
    LightRequired: 'Partial Sun',
    price: 1.99,
  },
  {
    id: '41',
    img: 'purple-coneflower.jpg',
    name: 'purple coneflower',
    subname: 'echinacea purpurea',
    description:
      'Sturdy, upright-growing plants produce large daisy-type flowers, each with purple petals and a yellow-gold center or cone. Flowers are produced all summer into fall, even in the hottest weather, and are great for attracting butterflies. Excellent cut flowers. Start Indoors 8-10 weeks before last expected frost',
    daysSprout: '14-21',
    seedSpacing: '1"/2.5cm',
    plantSpacing: '18"/45cm',
    daysHarvest: 'N/A',
    plantHeight: '36"/90cm',
    LightRequired: 'Full Sun',
    price: 2.99,
  },
  {
    id: '42',
    img: 'bee-balm.jpg',
    name: 'bee balm',
    subname: 'bergamot',
    description:
      'Bee Balm, also known as Bergamot, is a beautiful plant with violet, tube-shaped flowers. Tall growing pollinator with fragrant blooms. Great attractor for beneficial butterflies, hummingbirds, bees, and other pollinators. Start Indoors 6-8 weeks before last expected frost',
    daysSprout: '14-21',
    seedSpacing: '0.5"/12mm',
    plantSpacing: '18"/45cm',
    daysHarvest: 'N/A',
    plantHeight: '4"/1.2m',
    LightRequired: 'Full/Partial',
    price: 2.99,
  },
  {
    id: '43',
    img: 'veronica-speedwell.jpg',
    name: 'veronica speedwell',
    subname: 'lavender blue',
    description:
      'This species offers some of the richest blue flowers found in the garden. Long-lasting flower spikes add colour and contrast to arrangements and in borders, rock gardens and informal "naturalized" sanctuaries. Start Indoors 6-8 weeks before last expected frost',
    daysSprout: '14-21',
    seedSpacing: '2"/5cm',
    plantSpacing: '18"/45cm',
    daysHarvest: 'N/A',
    plantHeight: '4"/1.2m',
    LightRequired: 'Full Sun',
    price: 1.99,
  },
  {
    id: '44',
    img: 'dusty-miller.jpg',
    name: 'dusty miller',
    subname: 'silverdust',
    description:
      "One of the garden's most attractive foliage plants. Although primarily grown for the attractive silvery-grey foliage, plants bear small daisy-like yellow flowers. Plants tend to soften the garden's overall appearance and accent nearby colours. Start Indoors 8-10 weeks before last expected frost",
    daysSprout: '14-21',
    seedSpacing: '12"/30cm',
    plantSpacing: '18"/45cm',
    daysHarvest: 'N/A',
    plantHeight: '24"/60cm',
    LightRequired: 'Full/Partial',
    price: 1.99,
  },
  {
    id: '45',
    img: 'eucalyptus.jpg',
    name: 'eucalyptus',
    subname: 'silver drop',
    description:
      'Small, silvery green leaves on well-branched stems are ideal for fresh or dried bouquets. Excellent cut or dried flower foliage. Accent in beds. Excellent container plants. Drought tolerant and low maintenance. Start indoors 10-12 weeks before last expected frost',
    daysSprout: '14-21',
    seedSpacing: '1"/2.5cm',
    plantSpacing: '24"/60cm',
    daysHarvest: 'N/A',
    plantHeight: '36"/90cm',
    LightRequired: 'Full Sun',
    price: 3.99,
  },
  {
    id: '46',
    img: 'mimosa.jpg',
    name: 'mimosa',
    subname: 'sensitive plant',
    description:
      'Mimosa Sensitive plant, also called humble plant, responds to touch. The compound leaves fold inward and droop when touched or shaken, defending themselves from harm, and re-open a few minutes later. As the plants mature, the form small puffs of pink bloom which add ar extra benefit to this fascinating species. Year-round indoors',
    daysSprout: '42-56',
    seedSpacing: '1"/2.5cm',
    plantSpacing: '8"/20cm',
    daysHarvest: 'N/A',
    plantHeight: '12"/30cm',
    LightRequired: 'Full Sun',
    price: 2.99,
  },
  {
    id: '47',
    img: 'godetia.jpg',
    name: 'godetia',
    subname: 'double mix',
    description:
      'Strong plants are smothered with candle-like buds that open to produce satiny flower cups slightly more than 1" (2.5cm) across. Flowers have a mild honey fragrance and range from white to rose, pink and lavender, with splashes of dark markings. Use in mass plantings, mixed with other flowers, containers, cut flowers. Prefers cool weather.',
    daysSprout: '7-14',
    seedSpacing: 'Scatter',
    plantSpacing: '10"/25cm',
    daysHarvest: 'N/A',
    plantHeight: '30"/75cm',
    LightRequired: 'Full/Partial',
    price: 1.99,
  },
  {
    id: '48',
    img: 'poppy-red.jpg',
    name: 'poppy',
    subname: 'red flanders',
    description:
      "Provide bright red summer colour in nearly any soil. Drought tolerant and self-sow fairly reliably without ever becoming weedy. It's a good choice for any areas where grasses are allowed to grow tall in summer. Direct sow in early spring as soon as soil is workable",
    daysSprout: '14-28',
    seedSpacing: '1"/2.5cm',
    plantSpacing: '12"/30cm',
    daysHarvest: 'N/A',
    plantHeight: '24"/60cm',
    LightRequired: 'Full Sun',
    price: 2.99,
  },
  {
    id: '49',
    img: 'rose-mallow.jpg',
    name: 'rose mallow',
    subname: 'lavatera trimestris',
    description:
      'A long time favourite, rose mallow grows quickly into an upright bushy annual covered with stunning hibiscus-like flowers. The satiny, glistening petals are rose pink with darker veins. Use for a temporary summer hedge or screen, background; good for cutting. Direct sow in early spring as soon as soil is workable',
    daysSprout: '14-21',
    seedSpacing: '1"/2.5cm',
    plantSpacing: '18"/45cm',
    daysHarvest: 'N/A',
    plantHeight: '4"/1.2m',
    LightRequired: 'Full/Partial',
    price: 1.99,
  },
  {
    id: '50',
    img: 'silver-dollar.jpg',
    name: 'silver dollar',
    subname: 'money plant lunaria',
    description:
      'This unique biennial is usually grown for its beautiful flat translucent seed pods, popular in dried arrangements. Plants are also well known for their attractive, sweet-scented, purple or white blossoms. Start Indoors 8-10 weeks before last expected frost',
    daysSprout: '14-21',
    seedSpacing: '1"/2.5cm',
    plantSpacing: '15"/38cm',
    daysHarvest: 'N/A',
    plantHeight: '36"/90cm',
    LightRequired: 'Full Sun',
    price: 1.99,
  },
  {
    id: '51',
    img: 'sunflower-teddy.jpg',
    name: 'sunflower',
    subname: 'teddy bear',
    description:
      'Brilliant double golden-yellow flowers are produced on dwarf plants. The large seeds are easy to sow, sprout quickly, and develop into attractive plants. They will produce seeds that are good to eat and are attractive to birds. Direct sow in early spring as soon as soil is workable',
    daysSprout: '14-21',
    seedSpacing: '6"/15cm',
    plantSpacing: '24"/60cm',
    daysHarvest: 'N/A',
    plantHeight: '4"/1.2m',
    LightRequired: 'Full Sun',
    price: 2.99,
  },
  {
    id: '52',
    img: 'venus-fly-trap.jpg',
    name: 'venus fly trap',
    subname: 'snappy',
    description:
      'The Venus Fly Trap is a captivating carnivorous plant, famed for its jaw-like leaves that snap shut on unsuspecting insects. Provide a humid environment with sphagnum moss, mineral-free water, and plenty of light. Year-round indoors',
    daysSprout: '28-42',
    seedSpacing: '1"/2.5cm',
    plantSpacing: '4"/10cm',
    daysHarvest: 'N/A',
    plantHeight: ' 6"/15cm',
    LightRequired: 'Full Sun',
    price: 9.99,
  },
  {
    id: '53',
    img: 'viola.jpg',
    name: 'viola',
    subname: 'johnny jump up',
    description:
      'These hardy plants bloom profusely, producing small, dainty bicolour blossoms. Plants are easy-to-grow, often blooming the first year, and have a long blooming season. Named for how quickly they "jump up" in the spring. Start Indoors 4-6 weeks before last expected frost',
    daysSprout: '14-21',
    seedSpacing: '0.5"/12mm',
    plantSpacing: '10"/25cm',
    daysHarvest: 'N/A',
    plantHeight: '8"/20cm',
    LightRequired: 'Full/Partial',
    price: 2.99,
  },
  {
    id: '54',
    img: 'yarrow.jpg',
    name: 'yarrow',
    subname: 'pink',
    description:
      "The yarrow plant is an herbaceous flowering perennial. Whether you decide to grow yarrow in your flower beds or in your herb garden, it's a lovely addition to your yard. Yarrow care is so easy that the plant is virtually care-free. Start Indoors 6-8 weeks before last expected frost",
    daysSprout: '14-21',
    seedSpacing: '1"/2.5cm',
    plantSpacing: '24"/60cm',
    daysHarvest: 'N/A',
    plantHeight: '36"/90cm',
    LightRequired: 'Full Sun',
    price: 1.99,
  },
  {
    id: '55',
    img: 'moon-flower.jpg',
    name: 'moon flower',
    subname: 'giant white scented',
    description:
      "Fragrant, giant white flowers (6'/15cm across) open in the late afternoon and remain open until midmorning of the following day. Plants grow to 15/4.6m high and have large, decorative, heart-shape leaves. Use on screens, trellises, amp posts, mailboxes, dead trees, anywhere climbing colour is desired. Also good in banks. Start Indoors 6-8 weeks before last expected frost",
    daysSprout: ' 7-14',
    seedSpacing: '1"/2.5cm',
    plantSpacing: ' 6"/15cm',
    daysHarvest: 'N/A',
    plantHeight: '15"/4.5m',
    LightRequired: 'Full Sun',
    price: 1.99,
  },
  {
    id: '56',
    img: 'carnation-chabaud.jpg',
    name: 'carnation',
    subname: 'chabaud mix',
    description:
      'This bouquet staple is known for its colourful, fragrant, and fringed flowers. Fully double blooms are borne on strong stems and come in white, red, and shades of pink. Mixes well with other sun-loving flowers in your garden. Start Indoors 8-10 weeks before last expected frost',
    daysSprout: '21-28',
    seedSpacing: '1"/2.5cm',
    plantSpacing: '12"/30cm',
    daysHarvest: 'N/A',
    plantHeight: '24"/60cm',
    LightRequired: 'Full Sun',
    price: 1.99,
  },
  {
    id: '57',
    img: 'columbine.jpg',
    name: 'columbine',
    subname: 'aquilegia mckana mix',
    description:
      'Low mounds of delicate foliage give rise to colourful, intricately shaped flowers in spring and early summer. Flowers are usually bicoloured and include white, yellow, pink, blue, red, and lavender. Use in mass plantings and mixed with other late Spring flowers. Start Indoors 8-10 weeks before last expected frost',
    daysSprout: '21-28',
    seedSpacing: '0.5"/12mm',
    plantSpacing: '24"/60cm',
    daysHarvest: 'N/A',
    plantHeight: '36"/90cm',
    LightRequired: 'Full/Partial',
    price: 1.99,
  },
  {
    id: '58',
    img: 'black-eyed-susan.jpg',
    name: 'black eyed susan',
    subname: 'thunbergia',
    description:
      'This fast-growing, exotic-appearing vine covers itself with colourful flowers all summer and fall. The tubular flowers, up to 1 1/2" (4 cm.) across, are orange or yellowish with contrasting black throats. Plants twine and easily scramble up low fences., trellises, netting, and posts forming a screen. Trail over a low wall or from hanging baskets or urns. Start Indoors 6-8 weeks before last expected frost',
    daysSprout: '7-14',
    seedSpacing: '4"/10cm',
    plantSpacing: '20"/50cm',
    daysHarvest: 'N/A',
    plantHeight: '6"/1.8m',
    LightRequired: 'Full Sun',
    price: 1.99,
  },
  {
    id: '59',
    img: 'snapdragon.jpg',
    name: 'snapdragon',
    subname: 'giant tetra mix',
    description:
      'Vigorous, strong-stemmed plants produce masses of stunning flowers in white, and shades of yellow, pink, orange, red and crimson. Children enjoy the dragon mouth flowers that open and close when gently squeezed. Start Indoors 8-10 weeks before last expected frost',
    daysSprout: '14-21',
    seedSpacing: '0.5"/12mm',
    plantSpacing: '12"/30cm',
    daysHarvest: 'N/A',
    plantHeight: '24"/60cm', 
    LightRequired: 'Full Sun',
    price: 1.99,
  },
  {
    id: '60',
    img: 'statice.jpg',
    name: 'statice',
    subname: 'sea lavender',
    description:
      'These attractive, easy-to-grow annuals produce open clusters of colourful flowers during summer and fall. Colours include blue, lavender, rose, cream, and white. Flowers are durable and excellent for use fresh or dried. Start Indoors 8-10 weeks before last expected frost',
    daysSprout: '14-21',
    seedSpacing: '1"/2.5cm',
    plantSpacing: '12"/30cm',
    daysHarvest: 'N/A',
    plantHeight: '30"/75cm', 
    LightRequired: 'Full Sun',
    price: 1.99,
  },
  {
    id: '61',
    img: 'statice-latifolia.jpg',
    name: 'latifolia pacific mix',
    subname: 'sea lavender',
    description:
      'An ideal cut flower, Statice has long, strong stems with clusters of brightly coloured, papery blossoms that are excellent in dried or fresh cut arrangements. A vigorous annual that will keep blooming as long as you keep cutting the flowers as they emerge. Start Indoors 6-8 weeks before last expected frost',
    daysSprout: '7-14',
    seedSpacing: '2"/5cm',
    plantSpacing: '10"/25cm',
    daysHarvest: 'N/A',
    plantHeight: '15"/38cm', 
    LightRequired: 'Full Sun',
    price: 2.99,
  },
]
