import React from 'react'
import { NavLink } from 'react-router-dom'
import { Navigation } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/navigation'
import flowersImg from '../assets/img/flowers-img.jpg'
import field from '../assets/img/field.jpg'
import { ReactComponent as LearnMoreArrow } from '../assets/svg/learn-more-arrow.svg'

import { ReactComponent as PrevArrowIcon } from '../assets/svg/prevArrowIcon.svg'
import { ReactComponent as NextArrowIcon } from '../assets/svg/nextArrowIcon.svg'
import productsData from '../db/productsData'

function HomePage() {
  return (
    <>
      <section className='header-content'>
        <div className='container'>
          <div className='header-content__inner'>
            <div className='header-content-box'>
              <h1>Seeds of Change, Blooms of Possibility!</h1>
              <NavLink to='/shop' className={'radius-btn'}>
                Shop now
              </NavLink>
              <span>Cultivate Your Dreams with Seed!</span>
            </div>
            <div className='header-content-img'>
              <img src={flowersImg} alt='' loading='lazy' />
            </div>
          </div>
        </div>
      </section>
      <div className='seed-adventure'>
        <div className='container'>
          <div className='seed-adventure__inner'>
            <div className='seed-adventure-col'>
              <div className='seed-adventure-title'>
                Start Your Seed Adventure
              </div>
            </div>
            <div className='seed-adventure-col'>
              <div className='seed-adventure-learn-more'>
                <p>
                  Whether you're a seasoned gardener or a curious beginner, Seed
                  is your gateway to a world of botanical wonders. Let's grow
                  together and make every seed count!
                </p>
                <NavLink to='/'>
                  Learn more
                  <LearnMoreArrow />
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='banner'>
        <div className='container'>
          <div className='banner__inner'>
            <img src={field} alt='field' loading='lazy' />
            <div className='banner__inner-title'>
              We put everything together
            </div>
          </div>
        </div>
      </div>
      <section className='shop-swiper'>
        <div className='container'>
          <div className='shop-swiper-head'>
            <div className='shop-swiper-head-label'>OUR SHOP</div>
            <h3>Here are the best seller from our varieties</h3>
            <NavLink to='/shop' className={'radius-btn'}>
              See all
            </NavLink>
          </div>
        </div>
        <div className='container-right'>
          <div className='shop-swiper-slider'>
            <Swiper
              modules={[Navigation]}
              lazy={true}
              navigation={{
                prevEl: '.prev',
                nextEl: '.next',
              }}
              breakpoints={{
                1024: {
                  slidesPerView: 3.5,
                  spaceBetween: 44,
                },
                768: {
                  spaceBetween: 20,
                },
                500: {
                  slidesPerView: 2.5,
                  spaceBetween: 20,
                },
                320: {
                  slidesPerView: 1.5,
                  spaceBetween: 20,
                },
              }}
            >
              {productsData.slice(0, 10).map((product) => {
                return (
                  <SwiperSlide key={product.id}>
                    <NavLink className='shop-swiper-slider-con' to={`/shop/${product.id}`}>
                      <div className='shop-swiper-slider-con-img'>
                        <img
                          src={require(`../assets/img/product/${product.img}`)}
                          alt={product.img.toString().slice(0, -4)}
                          loading='lazy'
                        />
                      </div>
                      <div className='shop-swiper-slider-con-footer'>
                        <div className='shop-swiper-slider-con-footer-title'>
                          {product.name} | {product.subname}
                        </div>
                        <div className='shop-swiper-slider-con-footer-price'>
                          $ {product.price}
                        </div>
                      </div>
                    </NavLink>
                  </SwiperSlide>
                )
              })}
              <div className='shop-swiper-slider-icons'>
                <div className='prev'>
                  <PrevArrowIcon />
                </div>
                <div className='next'>
                  <NextArrowIcon />
                </div>
              </div>
            </Swiper>
          </div>
        </div>
      </section>
      <div className='block-subscribe'>
        <div className='container'>
          <div className='block-subscribe__inner'>
            <h3>Stay update with special offers, seeds tips, and more.</h3>
            <div className='block-subscribe__inner-input'>
              <input type='text' placeholder='Your email' />
              <button className='radius-btn'>Subscribe</button>
            </div>
          </div>
        </div>
      </div>
      <section className='faq'>
        <div className='container'>
          <div className='faq__inner'>
            <div className='faq__inner-col'>
              <div className='faq__inner-head'>
                <div className='faq__inner-title'>FAQ</div>
                <div className='faq__inner-text'>
                  Below are some of our common questions:
                </div>
              </div>
            </div>
            <div className='faq__inner-col'>
              <div className='faq__inner-lists'>
                <div className='faq__inner-list'>
                  <h4>How many seeds do you put in an individual packet?</h4>
                  <p>
                    We don't have seed counts per packet, but as a general rule
                    we put enough seed per packet to do a 10ft row or more in
                    your garden.
                  </p>
                </div>
                <div className='faq__inner-list'>
                  <h4>How much is shipping?</h4>
                  <p>
                    We have a $6 shipping option for small envelope orders with
                    a few packs of seed. After the order reaches a certain
                    size/weight, Canada Post automatically calculates the
                    shipping rate, which is normally $12-25.
                  </p>
                </div>
                <div className='faq__inner-list'>
                  <h4>
                    What is the difference between hybrid, heirloom, and
                    open-pollinated?
                  </h4>
                  <p>
                    Hybrids are the product of controlled crossbreeding for
                    specific desirable traits. Open-pollinated varieties rely
                    instead on natural pollination methods, such as wind,
                    insects, or birds, resulting in offspring that closely
                    resemble the parent plant. Heirlooms, which are older (50+
                    year) varieties that are always open-pollinated, are
                    cherished for their historical and regional significance,
                    offering stable traits across generations.
                  </p>
                </div>
                <div className='faq__inner-list'>
                  <h4>How deep should I plant my seeds?</h4>
                  <p>
                    As a rule of thumb, seeds should be planted at twice their
                    width or diameter. For example, small seeds of 1/16 inch
                    width should be planted 1/8 inch deep, while large bean
                    seeds up to 1/2 inch wide need to be planted 1 inch deep.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export { HomePage }
