import React from 'react'
import { NavLink } from 'react-router-dom'
import CartItem from './CartItem'
function ShopCart({ setIsOpen }) {
  return (
    <>
      <div className='shop-cart-bg' onClick={() => setIsOpen()} />
      <div className='shop-cart-wrapper'>
        <div className='shop-cart'>
          <div className='shop-cart-title'>Cart</div>
          <div className='shop-cart-items'>
            <CartItem
              title={'LUPINE | MIXED COLOURS'}
              price={1.99}
              img={'lupine.jpg'}
            />
            <CartItem
              title={'PASSIONFLOWER | BLUE CROWN'}
              price={2.99}
              img={'passionflower.jpg'}
            />
            <CartItem
              title={'VENUS FLY TRAP | SNAPPY'}
              price={9.99}
              img={'venus-fly-trap.jpg'}
            />
          </div>
          <div className='shop-cart-checkout'>
            <div className='shop-cart-checkout-subtotal'>
              <div className='shop-cart-checkout-subtotal-text'>Subtotal</div>
              <div className='shop-cart-checkout-subtotal-price'>$ 16.96</div>
            </div>
            <div className='shop-cart-checkout-total'>
              <div className='shop-cart-checkout-total-text'>Total</div>
              <div className='shop-cart-checkout-total-price'>$16.96</div>
            </div>
            <NavLink
              to={'/cart'}
              className='shop-cart-checkout-btn'
              onClick={() => setIsOpen()}
            >
              Checkout
            </NavLink>
            <NavLink
              to={'/'}
              className='shop-cart-checkout-link'
              onClick={() => setIsOpen()}
            >
              View Cart
            </NavLink>
          </div>
        </div>
      </div>
    </>
  )
}

export { ShopCart }
