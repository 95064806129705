import { useEffect } from 'react'
import { Routes, Route, useLocation } from 'react-router-dom'
import { HomePage } from './pages/HomePage'
import { ShopPage } from './pages/ShopPage'
import { ProductDetailPage } from './pages/ProductDetailPage'
import { ContactPage } from './pages/ContactPage'
import { Cart } from './pages/Cart'
import { PrivatePolicy } from './pages/PrivatePolicy'
import { Login } from './pages/Login'
import { SignUp } from './pages/SignUp'
import { ResetPassword } from './pages/ResetPassword'
import { NotfoundPage } from './pages/NotfoundPage'
import { Layout } from './components/Layout'

/**
 * In this project, I decided to use scss, so that I could easily type it
 * and have additional features like file separation and the use of constants
 */
import './assets/scss/style.scss'

/**
 * The NotfoundPage works as a 404 page, if the path is incorrect then it will not load this page.
 */
function App() {
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])
  return (
    <>
      <Routes>
        <Route path='/' element={<Layout />}>
          <Route index element={<HomePage />} />
          <Route path='shop' element={<ShopPage />} />
          <Route path='shop/:productId' element={<ProductDetailPage />} />
          <Route path='contact' element={<ContactPage />} />
          <Route path='cart' element={<Cart />} />
          <Route path='private-policy' element={<PrivatePolicy />} />
          <Route path='login' element={<Login />} />
          <Route path='sign-up' element={<SignUp />} />
          <Route path='reset-password' element={<ResetPassword />} />

          <Route path='*' element={<NotfoundPage />} />
        </Route>
      </Routes>
    </>
  )
}

export default App
