import React from 'react'
import authBg from '../assets/img/auth-bg.jpg'
function Login() {
  return (
    <div className='auth' style={{background: `url(${authBg}) center / cover no-repeat`}}>
      <div className='auth-container'>
        <div className='auth__inner'>
          <div className='auth-box'>
            <h2>Greetings! Welcome to Farm seeds.</h2>

            <div className='auth-box-input'>
              <label>Use your mobile number to sign up or log in</label>
              <input type='text' className='input' placeholder='+1 XXX XXX XX XX' />
            </div>
            <button className='btn'>continue</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export { Login }
