import React from 'react'
import authBg from '../assets/img/auth-bg.jpg'
function ResetPassword() {
  return (
    <div
      className='auth'
      style={{ background: `url(${authBg}) center / cover no-repeat` }}
    >
      <div className='auth-container'>
        <div className='auth__inner'>
          <div className='auth-box sing'>
            <h2>Reset your password</h2>
            <div className='auth-box-description'>
              Please provide your phone number below to receive a code for
              restoring access to your account
            </div>

            <div className='auth-box-input'>
              <label>Enter your mobile number</label>
              <input
                type='text'
                className='input'
                placeholder='+380 XX XXX XX XX'
              />
            </div>
            <button className='btn'>continue</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export { ResetPassword }
