import React, { useState } from 'react'
import { ReactComponent as СounterMinus } from '../assets/svg/counterMinus.svg'
import { ReactComponent as СounterPlus } from '../assets/svg/counterPlus.svg'

export default function CounterInput() {
  const [counter, setCounter] = useState(1)

  const handleClickPlus = () => {
    setCounter(counter + 1)
  }

  const handleClickMinus = () => {
    if (counter > 1) {
      setCounter(counter - 1)
    }
  }


  return (
    <div className='counter-input'>
      <button className='counter-input-btn counter-input-btn-minus'>
        <СounterMinus onClick={handleClickMinus} />
      </button>
      <span>{counter} </span>
      <button className='counter-input-btn counter-input-btn-plus'>
        <СounterPlus onClick={handleClickPlus} />
      </button>
    </div>
  )
}
