import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import productsData from '../db/productsData'
import { NavLink } from 'react-router-dom'
import { ReactComponent as Minus } from '../assets/svg/minus.svg'
import { ReactComponent as Plus } from '../assets/svg/plus.svg'

function ProductDetailPage() {
  const { productId } = useParams()
  const thisProduct = productsData.find((prod) => prod.id === productId)

  const [counter, setCounter] = useState(1)

  const handleClickPlus = () => {
    setCounter(counter + 1)
  }

  const handleClickMinus = () => {
    if (counter > 1) {
      setCounter(counter - 1)
    }
  }

  return (
    <>
      <div className='breadcrumbs'>
        <div className='container'>
          <div className='breadcrumbs__inner'>
            <NavLink to='/shop'>shop</NavLink> /{' '}
            <span> {thisProduct.name}</span>
          </div>
        </div>
      </div>
      <div className='prod-detail'>
        <div className='container'>
          <div className='prod-detail-con'>
            <div className='prod-detail-con-img'>
              <img
                src={require(`../assets/img/product/${thisProduct.img}`)}
                alt={thisProduct.img.toString().slice(0, -4)}
                loading='lazy'
              />
            </div>
            <div className='prod-detail-con-info'>
              <div className='prod-detail-con-info-title'>
                {thisProduct.name} | {thisProduct.subname}
              </div>
              <div className='prod-detail-con-info-description'>
                <div className='prod-detail-con-info-description-text'>
                  DESCRIPTION
                </div>
                <p>{thisProduct.description}</p>
              </div>

              <div className='prod-detail-con-info-growing'>
                <div className='prod-detail-con-info-growing-title'>
                  GROWING INFORMATION
                </div>
                <ul>
                  <li>
                    Days to Sprout: <span>{thisProduct.daysSprout}</span>
                  </li>
                  <li>
                    Seed Spacing: <span>{thisProduct.seedSpacing}</span>
                  </li>
                  <li>
                    Plant Spacing: <span>{thisProduct.plantSpacing}</span>
                  </li>
                  <li>
                    Days to Harvest: <span>{thisProduct.daysHarvest}</span>
                  </li>
                  <li>
                    Plant Height:  <span>{thisProduct.plantHeight}</span>
                  </li>
                  <li>
                    Light Required: <span>{thisProduct.LightRequired}</span>
                  </li>
                </ul>
              </div>
              <div className='prod-detail-con-info-col'>
                <div className='prod-detail-con-info-counter'>
                  Quantity
                  <div className='counter'>
                    <button className='counter-minus' onClick={handleClickMinus}>
                      <Minus />
                    </button>
                    <span>{counter}</span>
                    <button className='counter-plus' onClick={handleClickPlus}>
                      <Plus />
                    </button>
                  </div>
                </div>
                <div className='prod-detail-con-info-price'>
                price $ {thisProduct.price}
                </div>
              </div>
              <button className='btn'>Add to basket</button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export { ProductDetailPage }
