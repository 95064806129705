import React, { useState, useRef } from 'react'
// import { useOutsideClick } from '../hooks/useOutsideClick'
import { NavLink } from 'react-router-dom'

/**
 * Here I have used the following approach for svg, so that it is possible to edit them
 */
import { ReactComponent as Logo } from '../assets/svg/logo.svg'
// import { ReactComponent as HeaderArrow } from '../assets/svg/header-arrow.svg'
import { ReactComponent as UserIcon } from '../assets/svg/user.svg'
import { ReactComponent as CartIcon } from '../assets/svg/cart.svg'
import { ReactComponent as SearchIcon } from '../assets/svg/search.svg'

function Header({ setIsOpen }) {
  const [isActiveDropdown, setActiveDropdown] = useState(false)
  const [isActiveMenuBtn, setActiveMenuBtn] = useState(false)

  /**
   * Switch for drop down
   */
  const handleToggleDropdown = () => {
    setActiveDropdown(!isActiveDropdown)
  }

  /**
   * Switch for hamburger menu
   */
  const handleToggleMenuBtn = () => {
    setActiveMenuBtn(!isActiveMenuBtn)
    document.body.classList.toggle('hiden')
  }

  /**
   * The function of closing elements when clicking on outside the element
   */
  // const tooltipRef = useRef(null)
  // const onCloseOutside = () => {
  //   setActiveDropdown(false)
  // }
  // useOutsideClick(tooltipRef, onCloseOutside, isActiveDropdown)

  const closeSideBar = () => {
    setActiveMenuBtn(false)
    console.log('111')
  }
  return (
    <>
      <header className='header'>
        <div className='container'>
          <div className={`header__inner ${isActiveMenuBtn ? 'active' : ''}`}>
            <div className='header__inner-logo'>
              <NavLink to='/'>
                <Logo />
              </NavLink>
            </div>
            <nav
              className={`header__inner-nav ${isActiveMenuBtn ? 'active' : ''}`}
            >
              <ul className='nav-list'>
                <li className='nav-list-item'>
                  <NavLink to='/' onClick={closeSideBar}>
                    Home
                  </NavLink>
                </li>
                {/* <li
                  ref={tooltipRef}
                  className={`nav-list-item drop-down ${
                    isActiveDropdown ? 'active' : ''
                  }`}
                >
                  <span
                    className='nav-list-item-btn'
                    onClick={handleToggleDropdown}
                  >
                    Features
                    <HeaderArrow />
                  </span>
                  <div className='nav-list-item-drop-down'>
                    <ul>
                      <li>
                        <NavLink to='/'>Home</NavLink>
                      </li>
                      <li>
                        <NavLink to='/blog'>Blog</NavLink>
                      </li>
                      <li>
                        <NavLink to='/shop'>Shop</NavLink>
                      </li>
                      <li>
                        <NavLink to='/about'>About</NavLink>
                      </li>
                      <li>
                        <NavLink to='/contact'>Contact</NavLink>
                      </li>
                    </ul>
                  </div>
                </li> */}
                <li className='nav-list-item'>
                  <NavLink to='/contact' onClick={closeSideBar}>
                    Contact
                  </NavLink>
                </li>
                <li className='nav-list-item'>
                  <NavLink to='/shop' onClick={closeSideBar}>
                    Shop
                  </NavLink>
                </li>
              </ul>
              <div className='header__inner-link'>
                <div className='header__inner-link-item'>
                  <button>
                    <SearchIcon />
                  </button>
                </div>
                <div className='header__inner-link-item'>
                  <NavLink to='/login' onClick={closeSideBar}>
                    <UserIcon />
                  </NavLink>
                </div>
              </div>
            </nav>
            <div className='header__inner-link-item'>
              <button onClick={() => setIsOpen()}>
                <CartIcon />
              </button>
            </div>
            <div
              className={`header__menu-btn ${isActiveMenuBtn ? 'open' : ''}`}
              onClick={handleToggleMenuBtn}
            >
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
        </div>
      </header>
    </>
  )
}

export { Header }
