import React from 'react'
import hendPlant from '../assets/img/hend-plant.jpg'
function PrivatePolicy() {
  return (
    <div className='privat-policy'>
      <div className='privat-policy-img'>
        <img src={hendPlant} alt='hend Plant' />
      </div>
      <div className='privat-policy-info'>
        <h2>Privacy policy</h2>
        <div className='privat-policy-info-description'>
          <p>
            This Privacy Policy explains how Central Garden & Pet Company,
            owners of McKenzie Seeds, and its affiliated entities (collectively,
            “Central”, “we”, “us,” “our”) are committed to protecting individual
            privacy and maintaining the trust of our customers (“Customers”) and
            visitors to our websites (collectively, “Visitors”). It is important
            to us that we provide transparency regarding our collection, use,
            and disclosure of your personal data. This includes information that
            identifies, relates to, describes, is reasonably capable of being
            associated with, or could reasonably be linked, directly or
            indirectly, with a particular individual or household. We refer to
            this information interchangeably as “Personal Data” or “Personal
            Information” throughout this policy.
          </p>
          <p>
            To help us meet our commitment to you, we have created this Privacy
            Notice (“Privacy Notice”). This Privacy Notice governs data
            protection matters across our suite of products (collectively, the
            “Central Products” or “Products”), including data provided by
            Visitors to the websites we control and that link to this Privacy
            Notice (“Central Sites”). This Privacy Notice, along with our Terms
            of Use, form an integral and binding part of our relationship with
            you.
          </p>
          <p>1. What Does This Privacy Notice Apply to?</p>
          <p>
            This Privacy Notice describes how we use, share, and protect the
            Personal Data of Customers and Visitors who visit the Central Sites.
            It also describes the rights and choices regarding use, access to,
            and correction of Personal Data available.
          </p>
          <p>2. What Information Do We Collect and Why?</p>
          <p>
            We may collect Personal Data in various ways, such as when you:
            enter into a transaction or contractual arrangement with us;
            participate in our programs or activities; provide data at industry
            events and trade shows; visit our facilities or we visit you at your
            offices; contact our customer service; or in connection with your
            inquiries and communications with us. We may also collect or
            purchase Personal Data from other sources, including Customers, data
            companies, publicly-accessible databases, and joint marketing
            partners.
          </p>
          <p>A Quick Note on Tracking Data</p>
          <p>
            We use cookies, third-party analytics, and other tracking mechanisms
            to track Visitors’ to the Central Sites. These tracking mechanisms
            may automatically collect information about your visits to the
            Central Sites. You can read more about our Cookie Policy here.
          </p>
          <p>
            One of the third-party services that we use to track your activity
            and understand your behavior on our Platforms is Google Analytics.
            Information that we collect may be combined with other information
            about you using Google Analytics or other services. We use these
            technologies, alone or in combination, to understand user behavior
            (by tracking unique user IDs), and to target and measure the
            effectiveness of advertisements, help diagnose problems, recognize
            repeat Visitors, and facilitate your access to, and use of, the
            services offered on our Platforms.
          </p>
          <p>
            You can opt out of receiving interest-based Google ads or customize
            the ads Google shows you through your Google settings. If you do not
            want Google Analytics to collect and use information about your use
            of our Platforms, then you can adjust your settings in your web
            browser.
          </p>
          <p>3. What Are Our Legal Bases for Processing Personal Data?</p>
          <p>
            We collect and process Personal Data about you only where we have a
            legal basis for doing so. Our legal bases for processing Personal
            Data are dependent on the purpose and context of the processing
            activity. Our legal bases include: a. Legitimate Interests (Customer
            Data) We process Customer Data to further our own legitimate
            interests, which may include: providing our Customers with our
            Products; continuously improving, customizing, and personalizing our
            Products; taking measures to protect against fraud, spam, and abuse;
            and/or aggregating and/or anonymizing Customer Data, so that they
            will no longer be considered Personal Data. We do so to generate
            other data for our use, which we may use and disclose for any
            purpose. We will engage in this activity because we have a
            legitimate interest.
          </p>
          <p>
            b. Consent (Customer Data) We obtain consent to send marketing
            communications to our potential Customers and leads. Where you have
            provided your consent to receive marketing communications from us,
            you can withdraw your consent at any time by clicking on an
            unsubscribe link in each marketing communication or changing
            settings in your account, but doing so will not affect the
            processing that has already taken place.
          </p>

          <p>
            c. Contractual Necessity We process Customer Data based on
            contractual necessity, which may include providing the Products to
            our Customers.
          </p>
          <p>
            d. Legal Obligation Central may be required under EU, EU member
            state or other applicable law to obtain and store certain Personal
            Data. For example, Central may be required to obtain and store data
            relating to Customers for reporting purposes.
          </p>
          <p>4. How Do We Use Personal Data?</p>
          <p>
            We may use Personal Data for the following purposes: To provide you
            with the Products and services you request from us. This includes
            storing and retrieving Personal Data as needed by our Customers,
            processing transactions with Customers, communicating with our
            Customers, and for any other purposes that our Customers request; If
            you have consented to Central performing a background check in the
            employment process, we may also use your government identification
            number, and/or any other required identifier. The results of such
            background check will only be used for the purposes you consented to
            and will be kept strictly confidential. Please note that you are
            under no obligation to provide us with your government
            identification number (this is entirely optional) but failing to do
            so may hinder your ability to be employed by Central.
            Notwithstanding anything to the contrary in this Privacy Notice, in
            the event you choose to provide your government identification
            number, we will not use or disclose it except as necessary for the
            purpose of running a background check; To enforce our Terms of Use
            and any other agreements; As otherwise required or permitted by law;
            To improve, modify, and measure the performance of our Platforms.
          </p>
          <p>5. How Do We Disclose Personal Data?</p>
          <p>
            We will not disclose any Personal Data we collect from you to any
            third parties except as indicated below:
          </p>
          <p>
            Our Affiliates. We may disclose Personal Data to any of our
            subsidiaries and/or affiliates within the Central corporate group.
            Business Partners. We may partner with select retailers and other
            companies at various times to provide expanded services to our Site
            visitors. As part of such a relationship, we may share with these
            companies the use of certain interactive Site functions. Information
            provided by our Site visitors through the use of such interactive
            functions may be available to both us and our partners. Service
            Providers. We may disclose Personal Data to our service providers
            who perform certain services necessary to run our business (for
            example, data hosting and development, data analysis, customer
            service, auditing and other services), provided that these service
            providers have entered into legally binding agreements with us to
            protect the Personal Data shared, limit their use of the Personal
            Data, and assist us with our compliance requirements. Legal
            Request. We may disclose Personal Data to comply with applicable law
            and our regulatory monitoring and reporting obligations (which may
            include laws outside your country of residence), to respond to
            requests from public and government authorities (which may include
            authorities outside your country of residence), to cooperate with
            law enforcement, or for other legal reasons. Business Transfer. We
            may disclose Personal Data to a buyer or successor in the event of a
            merger, divestiture, restructuring, reorganization, dissolution, or
            other sale or transfer of some or all of Central’s assets, whether
            as a going concern or as part of bankruptcy, liquidation,
            receivership, or similar proceeding in which Personal Data held by
            Central are among the assets to be transferred.
          </p>
          <p>
            Enforcement of Our or Others’ Rights. We may disclose Personal Data
            to the extent they are necessary to enforce or protect our rights,
            privacy, safety or property, and/or that of our affiliates, you or
            others, including enforcing our Terms of Use and any other
            agreements (such as for billing and collection purposes and fraud
            prevention).
          </p>
          <p>6. Children</p>
          <p>
            We do not knowingly collect Personal Data from children under the
            age of sixteen (16) without authorization by a holder of parental
            responsibility. If you believe that we may have collected Personal
            Data from or about a child under the age of sixteen (16) without
            such authorization, please contact us here.
          </p>
          <p>7. How Do We Protect Your Personal Data?</p>
          <p>
            At Central we take the protection of your Personal Data seriously.
            We make every reasonable effort to prevent unauthorized use,
            disclosure, loss, or theft of your Personal Data. Central employees
            who have access to your Personal Data are made aware of the
            importance of keeping it confidential. Where we collect your payment
            card information, we will comply with all applicable Payment Card
            Industry Data Security Standards (PCI-DSS).
          </p>
          <p>
            When we rely on service providers that may require access to your
            Personal Information, we require them to have adequate privacy and
            security standards. We use contracts and other legally binding
            measures with our service providers to maintain the confidentiality
            and security of your Personal Data, and to prevent such information
            from being used for any other purpose.
          </p>
          <p>
            However, no data security measures can guarantee complete security;
            we also depend on you to take common sense steps to ensure your
            Personal Information remains secure.
          </p>
          <p>
            Unfortunately, the transmission of information via the Internet is
            not completely secure. Although we do our best to protect your
            Personal Data, we cannot guarantee the security of your Personal
            Information transmitted to our Platforms. Any transmission of
            Personal Data is at your own risk. We are not responsible for
            circumvention of any privacy settings or security measures contained
            on our Platforms.
          </p>
          <p>8. Information for California Consumers</p>
          <p>
            The information and rights in this section apply specifically to
            California residents.
          </p>
          <p>
            Central does not sell, trade, or rent out our Visitor’s Personal
            Information for compensation, nor have we done so in the preceding
            twelve months. California residents are entitled to contact us to
            request information about whether we have disclosed Personal
            Information to third parties for the third parties’ direct marketing
            purposes. Currently Central does not disclose Personal Information
            to third parties for their direct marketing purposes. Thus, upon
            receipt of such a request by a California consumer, and as required
            by California state law, we will either (1) respond with a
            confirmation that we have not disclosed any Personal Information to
            third parties in the previous calendar year, or (2) if our practices
            have changed, we will provide the categories of Personal Information
            that has been shared in the past 12 months and categories of third
            parties to whom such Personal Information was disclosed, whichever
            is relevant.
          </p>
          <p>California consumers have the right to:</p>
          <p>
            Request disclosure of the categories and specific pieces of Personal
            Information that Central has collected about you; Request disclosure
            of the categories of third-party sources, if any, from which Central
            has collected Personal Information about you; Disclosure of the
            business or commercial purpose(s) for which your Personal
            Information has been collected by Central; Receive a list of the
            categories of third parties with whom Central has shared your
            Personal Information; Request that Central delete any Personal
            Information that it has collected from you (subject to exceptions);
            and Not be discriminated against by Central (e.g. charged different
            rates, provided different levels of service, denied goods or
            services, or suggested any of the preceding) for exercising any of
            the individual rights granted above.
          </p>
          <p>
            To exercise any of your rights as a California consumer, you can
            submit a request here. Before complying with your request, we will
            need to verify that it is you that is making the request. To
            accomplish this, you may be requested to (1) confirm specific
            Personal Information that we already know about you; or (2) provide
            us with appropriate identification and documentation. California
            consumers are limited to two requests for Personal Information per
            twelve-month period. Only you or an authorized agent may make a
            verifiable data subject request related to your Personal Data. The
            verifiable data subject request must provide sufficient information
            and documentation to allow us to verify that you (or an authorized
            agent) are the person about whom we collected Personal Data. We will
            not provide you with Personal Data if we cannot verify your identity
            and/or authority to make the data subject request and confirm the
            Personal Data belongs to you or the represented individual. Making a
            verifiable data subject request does not require you to create an
            account with us. We use Personal Data provided in a verifiable data
            subject request solely to verify the requestor's identity or
            authority to make the request. We will acknowledge receipt of your
            data request within 10 days. We will respond to a verifiable data
            subject request within 45 days of its receipt. If we require more
            time (up to 90 days), we will inform you of the reason and extension
            period. If you have an account with us, we will deliver our response
            to the email address for that account. If you do not have an account
            with us, we will deliver our response by US mail or electronically
            at the email address in your request, at your option. All
            disclosures we provide will only cover the 12-month period preceding
            the verifiable employee request's receipt. The response we provide
            will also explain the reasons we cannot comply with a request, if
            applicable. We do not charge a fee to process or respond to your
            verifiable data subject request unless it is excessive, repetitive,
            or manifestly unfounded. If we determine that the request warrants a
            fee, we will tell you why we made that decision and provide you with
            a cost estimate before completing your request.
          </p>
          <p>9. Retaining Your Personal Data</p>
          <p>
            We retain Personal Data for as long as needed or permitted in light
            of the purpose(s) for which they were obtained and consistent with
            applicable law. The criteria used to determine our retention periods
            include: The length of time we have an ongoing relationship with you
            and provide the Products to you; Whether there is a legal obligation
            to which we are subject (for example, certain laws require us to
            keep records of your transactions for a certain period of time
            before we can delete them); or Whether retention is advisable in
            light of our legal position (such as in regard to applicable
            statutes of limitations, litigation or regulatory investigations).
          </p>
          <p>10. Your Individual Rights under the GDPR</p>
          <p>
            Under the General Data Protection Regulation (“GDPR”) (and subject
            to any relevant exceptions) you have the right to access, correct,
            change, delete, restrict, exercise your right to data portability,
            or object to the processing of Personal Data. As a Customer residing
            in, or located in, the EU or EEA, you can exercise your GDPR rights
            by submitting a request here. We may first request verification of
            your identity prior to facilitating the exercise of your rights. You
            can also elect not to receive marketing communications by changing
            your preferences in your account, or by following the unsubscribe
            instructions in each communication. We will retain Personal Data in
            order to comply with legal requirements, protect our and others’
            rights, resolve disputes, or enforce our legal terms or policies, to
            the extent permitted under applicable law. You may also lodge a
            complaint with an EU/EEA data protection authority for your country
            or region where you have your habitual residence or place of work or
            where an alleged infringement of applicable data protection law
            occurs.
          </p>
          <p>11. Transfers outside of the EEA</p>
          <p>
            Your Personal Data may be stored and processed in any country where
            we have facilities or in which we engage service providers, and by
            visiting our Sites and/or purchasing our Products you understand
            that your information will be transferred to countries outside of
            your country of residence, including the United States, which may
            have data protection rules that are different from those of your
            country. In certain circumstances, courts, law enforcement agencies,
            regulatory agencies or security authorities in those other countries
            may be entitled to access your Personal Data. Please note that when
            your Personal Data are located outside of the EEA, they may be
            subject to the laws of the country in which they are hosted
          </p>
          <p>12. Updates to this Privacy Notice</p>
          <p>
            It is our policy to post any changes we make to our Privacy Notice
            on this page, with a notice that it has been updated on our main
            homepage. If we make material changes to how we treat your Personal
            Data, we will notify you through a notice on the homepage. The date
            that this Privacy Notice was last revised is listed at the top of
            the page. You are responsible for visiting our site and this Privacy
            Notice to check for any changes.
          </p>
        </div>
      </div>
    </div>
  )
}

export { PrivatePolicy }
