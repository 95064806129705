import React from 'react'

function NotfoundPage() {
  return (
    <div className='container'>
      <div>NotfoundPage</div>
    </div>
  )
}

export { NotfoundPage }
