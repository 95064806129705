import React from 'react'
import { NavLink } from 'react-router-dom'
import hand from '../assets/img/shop/hand.jpg'
import plant from '../assets/img/shop/plant.jpg'
import productsData from '../db/productsData'
function ShopPage() {
  return (
    <div className='shop'>
      <div className='shop-panel-img'>
        <img src={hand} alt='hand' />
        <img src={plant} alt='plant' />
      </div>
      <div className='shop-list'>
        {productsData.map((product) => {
          return (
            <NavLink className='shop-list-item' to={`/shop/${product.id}`} key={product.id}>
              <div className='shop-list-item-img'>
                <img
                  src={require(`../assets/img/product/${product.img}`)}
                  alt={product.img.toString().slice(0, -4)}
                  loading='lazy'
                />
              </div>
              <div className='shop-list-item-info'>
                <div className='shop-list-item-info-title'>
                  {product.name} | {product.subname}
                </div>
                <div className='shop-list-item-info-price'>price $ {product.price}</div>
              </div>
            </NavLink>
          )
        })}
      </div>
    </div>
  )
}

export { ShopPage }
