import React from 'react'
import { ReactComponent as Close } from '../assets/svg/close.svg'
import CounterInput from './CounterInput'
export default function CartItem({ title, price, img }) {

  return (
    <div className='shop-cart-item'>
      <div className='shop-cart-item-left'>
        <div className='shop-cart-item-img'>
          <img
            src={require(`../assets/img/product/${img}`)}
            alt={img}
            loading='lazy'
          />
        </div>
        <div className='shop-cart-item-left-info'>
          <div className='shop-cart-item-title'>{title}</div>
          <CounterInput />
        </div>
      </div>
      <div className='shop-cart-item-right'>
        <div className='shop-cart-item-right-price'>$ {price}</div>
        <button>
          <Close />
        </button>
      </div>
    </div>
  )
}
