import React from 'react'
import { NavLink } from 'react-router-dom'
import { ReactComponent as EmailIcon } from '../assets/svg/emailIcon.svg'
import phoneCall from '../assets/img/phone-call.svg'
import sharpEmail from '../assets/img/sharp-email.svg'
import locationFilled from '../assets/img/location-filled.svg'
function Footer() {
  return (
    <footer className='footer'>
      <div className='container'>
        <div className='footer__inner'>
          <div className='footer__inner-panel'>
            <div className='footer__inner-panel-title'>
              STAY UPTO DATE ABOUT OUR LATEST OFFERS
            </div>
            <div className='footer__inner-panel-contact'>
              <div className='footer__inner-panel-contact-input'>
                <EmailIcon />
                <input type='email' placeholder='Enter your email address' />
              </div>

              <button>Subscribe to Newsletter</button>
            </div>
          </div>

          <div className='footer__inner-con'>
            <div className='footer__inner-info'>
              <div className='footer__inner-info-title'>Farm seeds</div>
              <p>
                We have flower seeds that perfectly match your garden's
                aesthetic and that you'll proudly nurture. From delicate blooms
                to bold blossoms, our diverse selection caters to every
                gardener's taste and style. Whether you're creating a vibrant
                floral display or adding a touch of elegance to your outdoor
                space, find the perfect flower seeds to express your gardening
                flair with us.
              </p>
            </div>
            <div className='footer__inner-links'>
              <div className='footer__inner-links-item'>
                <ul>
                  <li>
                    <NavLink to='/private-policy'>Privacy Policy</NavLink>
                  </li>
                  <li>
                    <NavLink to='/shop'>Shop</NavLink>
                  </li>
                  <li>
                    <NavLink to='/'>Order</NavLink>
                  </li>
                  
                </ul>
              </div>
              <div className='footer__inner-links-item'>
                <ul>
                  <li>
                    <NavLink to='/'>Payments</NavLink>
                  </li>
                  <li>
                    <NavLink to='/contact'>Contact</NavLink>
                  </li>
                
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export { Footer }
